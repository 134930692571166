<template>
    <div class="k-page-flex">
        <div class="k-page-title">
            <h1>Créer un projet</h1>
            <b-button
                size="sm"
                variant="outline-secondary"
                @click="goListProjects"
            >
                <b-icon-list-ul /> Liste des projets
            </b-button>
        </div>

        <div class="k-page-body">
            <FormProject />
        </div>
    </div>
</template>
<script>
import FormProject from "@/components/model/project/FormProject";
export default {
    components: {
        FormProject,
    },

    methods: {
        goListProjects() {
            this.$router.push({
                name: "AdminListProjects",
            });
        },
    },
};
</script>
